import { useAuthStore } from "~/common/stores/AuthStore";
import { storeToRefs } from "pinia";

export default defineNuxtPlugin((nuxtApp) => {
  const authStore = useAuthStore();
  const { authTokens } = storeToRefs(authStore);
  const config = useRuntimeConfig();

  // Determine if we're on server or client
  const isServer = process.server;

  // Initialize auth token from cookies during SSR
  if (isServer && !authTokens.value) {
    // Get cookies from the request headers
    const cookieHeader = useRequestHeaders(["cookie"]).cookie || "";

    // Try to extract token from cookies
    // You'll need to know your actual cookie name - adjust this regex accordingly
    const tokenMatch = cookieHeader.match(/token=([^;]+)/);

    if (tokenMatch && tokenMatch[1]) {
      const token = decodeURIComponent(tokenMatch[1]);
      // Set token in the auth store for SSR requests
      authTokens.value = `Bearer ${token}`;
    }
  }

  const baseURL =
    (config.public.apiBaseUrl as string | undefined) ??
    "http://localhost:3000/api";

  const $customFetch = $fetch.create({
    baseURL,

    onRequest({ options }) {
      // Clone headers to avoid modifying the shared object
      options.headers = new Headers(options.headers);

      // Add auth token if available
      if (authTokens.value) {
        options.headers.set("Authorization", `${authTokens.value}`);
      }

      // Add server-side specific headers if needed
      if (isServer) {
        // Additional server headers could go here if needed
      }
    },

    onResponseError(error) {
      // Handle authentication errors
      if (error.response?.status === 401) {
        // Only log user out on client-side to prevent SSR issues
        if (import.meta.client) {
          console.warn("Authentication token invalid or expired, logging out");

          // Add a small delay to ensure we don't interrupt the current request flow
          setTimeout(() => {
            authStore.resetAuthStore();

            // Only redirect if we're not already on the auth page
            const currentPath = window.location.pathname;
            if (!currentPath.startsWith("/auth")) {
              navigateTo("/auth");
            }
          }, 0);
        }
      }

      // Format the error message for UI display
      let message = "Something went wrong";

      try {
        if (error.response?._data?.error?.messages?.length > 0) {
          message = error.response._data.error.messages[0];
        } else if (error.response?._data?.message) {
          message = Array.isArray(error.response._data.message)
            ? error.response._data.message[0]
            : error.response._data.message;
        }
      } catch (err) {
        console.error("Error parsing response data:", err);
      }

      error.response._data = {
        ...(error.response._data || {}),
        message,
      };
    },
  });

  return {
    provide: {
      customFetch: $customFetch,
    },
  };
});
